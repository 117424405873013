import { clearOldStorage, setFormStorage } from '../utils'

// ? ******************************************************************************************
// ?  Local storage
// ? ******************************************************************************************

export const local = (vertical) => {
  const key = `zmarta_form_${vertical}`
  clearOldStorage(key, localStorage)

  const cl = (state) => ({
    form: setFormStorage(state.form, 'cl', true)
  })

  const sme = (state) => ({
    form: setFormStorage(state.form, 'sme', true)
  })

  const mortgage = (state) => ({
    form: setFormStorage(state.form, 'mortgage', true)
  })

  const electricity = (state) => ({
    form: setFormStorage(state.form, 'electricity', true)
  })

  return {
    key,
    storage: window.localStorage,
    reducer: (state) => ({
      build: state.build,
      loader: state.loader,
      modal: state.modal,
      ...(vertical === 'cl' ? cl(state) : {}),
      ...(vertical === 'sme' ? sme(state) : {}),
      ...(vertical === 'mortgage' ? mortgage(state) : {}),
      ...(vertical === 'electricity' ? electricity(state) : {})
    })
  }
}

export const verticalTracking = (vertical) => {
  return {
    key: `zmarta_tracking_${vertical}`,
    storage: window.localStorage,
    reducer: (state) => ({
      ...(vertical === 'cl' ? { cl: { tracking: state.cl.tracking } } : {}),
      ...(vertical === 'sme' ? { sme: { tracking: state.sme.tracking } } : {}),
      ...(vertical === 'mortgage' ? { mortgage: { tracking: state.mortgage.tracking } } : {}),
      ...(vertical === 'electricity' ? { electricity: { tracking: state.electricity.tracking } } : {})
    })
  }
}

// ? ******************************************************************************************
// ?  Session Storage
// ? ******************************************************************************************

export const session = (vertical) => {
  const key = `zmarta_form_${vertical}`
  clearOldStorage(key, sessionStorage)

  const cl = (state) => ({
    form: setFormStorage(state.form, 'cl'),
    cl: {
      analytics: state.cl.analytics,
      application: state.cl.application,
      debtRegistry: state.cl.debtRegistry,
      debts: state.cl.debts,
      form: state.cl.form,
      formAlternatives: state.cl.formAlternatives,
      formSteps: state.cl.formSteps,
      internalAccess: state.cl.internalAccess,
      loanIndicator: state.cl.loanIndicator,
      lookup: state.cl.lookup,
      snapshot: state.cl.snapshot
    }
  })

  const sme = (state) => ({
    form: setFormStorage(state.form, 'sme'),
    sme: {
      application: state.sme.application,
      authentication: state.sme.authentication,
      form: state.sme.form,
      formAlternatives: state.sme.formAlternatives,
      internalAccess: state.sme.internalAccess,
      loanWithSecurity: state.sme.loanWithSecurity,
      lookup: state.sme.lookup,
      lookupCompanyIdentifier: state.sme.lookupCompanyIdentifier
    }
  })

  const mortgage = (state) => ({
    form: setFormStorage(state.form, 'mortgage'),
    loader: state.loader,
    messages: state.messages,
    mortgage: {
      authentication: state.mortgage.authentication,
      form: state.mortgage.form
    }
  })

  const electricity = (state) => ({
    form: setFormStorage(state.form, 'electricity')
  })

  return {
    key,
    storage: window.sessionStorage,
    reducer: (state) => ({
      build: state.build,
      authentication: state.authentication,
      verify: state.verify,
      ...(vertical === 'cl' ? cl(state) : {}),
      ...(vertical === 'sme' ? sme(state) : {}),
      ...(vertical === 'mortgage' ? mortgage(state) : {}),
      ...(vertical === 'electricity' ? electricity(state) : {})
    })
  }
}

export const verticalSession = (vertical) => {
  return {
    key: `zmarta_session_${vertical}`,
    storage: window.sessionStorage,
    reducer: (state) => ({
      ...(vertical === 'cl' ? { cl: { session: state.cl.session } } : {}),
      ...(vertical === 'sme' ? { sme: { session: state.sme.session } } : {}),
      ...(vertical === 'mortgage' ? { mortgage: { session: state.mortgage.session } } : {}),
      ...(vertical === 'electricity' ? { electricity: { session: state.electricity.session } } : {})
    })
  }
}

export const zmartaSession = () => {
  return {
    key: 'zmarta_session',
    storage: window.sessionStorage,
    reducer: (state) => ({
      zmartaSession: state.zmartaSession
    })
  }
}
